// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  platform: 'local',
  host: 'http://localhost:4200',
  // origin:'https://localhost:44342/api/'
  origin: 'https://api.v1.zipmeds.co/api/'

  //origin: 'http://ec2-52-90-54-240.compute-1.amazonaws.com/api/',
  //origin: 'http://ec2-3-110-217-241.ap-south-1.compute.amazonaws.com/api/',
  // defaultauth: 'fackbackend',
  // firebaseConfig: {
  //   apiKey: '',
  //   authDomain: '',  
  //   databaseURL: '',
  //   projectId: '',
  //   storageBucket: '',
  //   messagingSenderId: '',
  //   appId: '',
  //   measurementId: ''
  // }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
