<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <a href="" target="_blank">Zip Meds</a>
                <span> &copy; 2022 All Rights Reserved</span>
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    Powered by
                    <a href="" target="_blank">
                        <span> Zip Meds</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>