import { environment } from '../../../environments/environment';


export class APIURL {

    //public PerpsionUpload_API_URL=environment.origin + 'customer/Prescription/upload-prescription'


    // login  controller
    public login_API_URL = environment.origin + 'access/auth/login';

    // menu urls

    public GetMenu = environment.origin + 'settings/menu'

    // role controller

    public createRoles_API_URL = environment.origin + 'access/role/'
    public DeleteRoles_API_URL = environment.origin + 'access/role/'
    public updateRoles_API_URL = environment.origin + 'access/role/'
    public GetRoles_API_URL = environment.origin + 'access/role/'
    public GetRoleByID_API_URL = environment.origin + 'access/role/'
    public RoleGetAll_API_URL = environment.origin + 'ApiExplorer'
    public ActiveDeactiveRole_API_URL = environment.origin + 'access/role/activate-de-activate'
    public DateFilterRole_API_URL = environment.origin + 'access/role/DateFilterRole'
    public RoleAdd_API_URL = environment.origin + 'shared/Profile/role-user'
    public RoleUpdate_API_URL = environment.origin + 'access/role/create-user-permission'
    public RoleGetByAuthId_API_URL = environment.origin + 'access/role/get-by-user/'
    public RoleTextSearch_API_URL = environment.origin + 'access/role/search'


    public Create_member_by_order_API_URL = environment.origin + 'access/auth/create-member-by-order-details'


    // auth controller

    public sharedProfile_API_URL = environment.origin + 'shared/Profile'
    public user_with_roles_API_URL = environment.origin + 'access/auth/get-user-with-roles'
    public ProfileAdd_API_URL = environment.origin + 'shared/Profile'
    public ProfileDelete_API_URL = environment.origin + 'shared/Profile/'
    public AddSecurity_API_URL = environment.origin + 'access/auth/create-member'
    public ActiveDeactiveUser_API_URL = environment.origin + 'access/auth/activate-de-activate'
    public SecurtyProfileByID_API_URL = environment.origin + 'access/auth/'
    public updateUserSecurety_API_URL = environment.origin + 'access/auth/update-member'
    public UserProfileUpdate_API_URL = environment.origin + 'shared/Profile'
    public UserByid_API_URL = environment.origin + 'shared/Profile/get-by-auth/'
    public UserByidedit_API_URL = environment.origin + 'shared/Profile/'
    public UserDateFilter_API_URL = environment.origin + 'shared/Profile/DateFilterMember'
    public AuthTextSearch_API_URL = environment.origin + 'access/auth/search'

    public ProfileOthersAdd_API_URL = environment.origin + 'doctor/staff/add-others'
    public ProfileOthersByID_API_URL = environment.origin + 'doctor/staff/get-by-auth/'
    public ProfileOthersUpdate_API_URL = environment.origin + 'doctor/staff'


    public GetUserMobileNumber_API_URL = environment.origin + 'access/auth/get-user-by-phone'



    // Category Controller

    public CategoryById_API_URL = environment.origin + 'shared/category/'
    public CategoryAll_API_URL = environment.origin + 'shared/category'
    public CategoryAdd_API_URL = environment.origin + 'shared/category'
    public CategoryUpdate_API_URL = environment.origin + 'shared/category'
    public CategoryDelete_API_URL = environment.origin + 'shared/category/'
    public DateFilterCategory_API_URL = environment.origin + 'shared/category/DateFilterCategory'
    public CategoryTextSearch_API_URL = environment.origin + 'shared/category/search'
    public CategoryActivateDeActivate_API_URL = environment.origin + 'shared/category/activate-de-activate'


    // Order  Controller

    public StoreProductOrder_API_URL = environment.origin + 'sale/order'
    public StoreProductOrder_GetByMarketStaff_URL = environment.origin + 'sale/order/get-by-market-staff'
    public StoreProductOrderById_API_URL = environment.origin + 'sale/order/'
    public FilterOrder_API_URL = environment.origin + 'sale/order/FilterOrder'
    public OrderSearchText_API_URL = environment.origin + 'sale/order/search'

    public Place_Order_Invoice_API_URL = environment.origin + 'sale/order/place-order';

    public PaymentModeApproved_API_URL = environment.origin + 'sale/order/update-paymentmode';

    


    // product  Controller

    public ProductAll_API_URL = environment.origin + 'store/product'
    public StoreProductImport_API_URL = environment.origin + 'store/product/upload-products'
    public AddProduct_API_URL = environment.origin + 'store/product'
    public ProductGetById_API_URL = environment.origin + 'store/product/'
    public EditProduct_API_URL = environment.origin + 'store/product'
    public DeleteProduct_API_URL = environment.origin + 'store/product/'
    public ActiveDeactiveProduct_API_URL = environment.origin + 'store/product/activate-de-activate'
    public DateFilterProduct_API_URL = environment.origin + 'store/product/DateFilterProduct'
    public SearchProduct_API_URL = environment.origin + 'store/product/search'


    // market Controller
    public StoreMarketProductImport_API_URL = environment.origin + 'store/Market/upload-inventory'
    public MarketProductAll_API_URL = environment.origin + 'store/Market/MarketProductAll'
    public MarketProductDateFilter_API_URL = environment.origin + 'store/Market/DateFilterMarketProduct'
    public MarketProductSearch_API_URL = environment.origin + 'store/Market/search'
    public MarketProductActivateDeActivate_API_URL = environment.origin + 'store/Market/activate-de-activate'

    public MarketProduct_SearchBy_code_Name_API_URL = environment.origin + 'store/Market/get-by-productcode'



    // store Controller

    public StoreAll_API_URL = environment.origin + 'store/market'
    public StoreByAuthID_API_URL = environment.origin + 'sale/order/get-by-market/'
    public StoreOrderByid = environment.origin + 'sale/order/'
    public StoreOrderStausUpdate_API_URL = environment.origin + 'sale/Order/update-status'
    public StoreDashboardCount_API_URL = environment.origin + 'store/market/Store-Dashboard-CountGet-All'

    public Store_Admin_DashboardCount_API_URL = environment.origin + 'store/market/Store_Admin_DashboardGetCountFilter'

    public AddOthersStaff_API_URL = environment.origin + 'store/staff/add-others-staff'
    public OthersStaffById_API_URL = environment.origin + 'store/staff/get-by-staff/'
    public UpdateOthersStaff_API_URL = environment.origin + 'store/staff/update-others-staff'

    // profile add Image

    public ProfileImageUpload_API_URL = environment.origin + 'shared/Profile/upload-profilimage';

    public ProfileImageShow_API_URL = environment.origin + 'customer/prescription/download/';


    // Pincode Controller

    public PinCodeGetAll_API_URL = environment.origin + 'shared/Pincode';
    public PinCodeGetByID_API_URL = environment.origin + 'shared/Pincode/';
    public PinCodeCreate_API_URL = environment.origin + 'shared/Pincode/Create-Pincode';
    public PinCodeUpdate_API_URL = environment.origin + 'shared/Pincode/Update-Pincode';
    public PinCodeDelete_API_URL = environment.origin + 'shared/Pincode/';
    public ActiveDeactivePincodes_API_URL = environment.origin + 'shared/Pincode/activate-de-activate'
    public DateFilterPincode_API_URL = environment.origin + 'shared/Pincode/DateFilterPincode'
    public SearchPincode_API_URL = environment.origin + 'shared/Pincode/search'


    // Organisation Controller

    public OrganisationGetAll_API_URL = environment.origin + 'doctor/organisation';
    public OrganisationGetByID_API_URL = environment.origin + 'doctor/organisation/';
    public OrganisationCreate_API_URL = environment.origin + 'doctor/organisation';
    public OrganisationUpdate_API_URL = environment.origin + 'doctor/organisation';
    public OrganisationDelete_API_URL = environment.origin + 'doctor/organisation/';
    public ActiveDeactiveOrganisation_API_URL = environment.origin + 'doctor/organisation/activate-de-activate'
    public Organisation_DashboardCountGetAll_API_URL = environment.origin + 'doctor/organisation/Organisation-Dashboard-CountGet-All'
    public DateFilterOrganisation_API_URL = environment.origin + 'doctor/organisation/DateFilterOrganisation'
    public OrganisationTextSearch_API_URL = environment.origin + 'doctor/organisation/search'


    public Org_DashboardCountDateFilterGetAll_API_URL = environment.origin + 'doctor/organisation/Org-Dashboard-DateFilter'

    


    // Doctor Controller

    public DoctorGetAll_API_URL = environment.origin + 'doctor/assignment';
    public DoctorGetByID_API_URL = environment.origin + 'doctor/assignment/';
    public DoctorCreate_API_URL = environment.origin + 'doctor/assignment';
    public DoctorUpdate_API_URL = environment.origin + 'doctor/assignment';
    public DoctorDelete_API_URL = environment.origin + 'doctor/assignment/';
    public DateFilterDoctorAssignment_API_URL = environment.origin + 'doctor/assignment/DateFilterDoctorAssignment'
    public DoctorListAll_API_URL = environment.origin + 'doctor/assignment/DoctorListAll'
    public DateFilterDoctorList_API_URL = environment.origin + 'doctor/assignment/DateFilterDoctorList'
    public VerifyCalling_OTP_API_URL = environment.origin + 'doctor/assignment/show-customer-number'
    public DoctorTextSearch_API_URL = environment.origin + 'doctor/assignment/doctorsearch'
    public AssignmentTextSearch_API_URL = environment.origin + 'doctor/assignment/assignmentsearch'





    public DoctorApproveReject_API_URL = environment.origin + 'doctor/assignment';
    public Generate_Prescription_API_URL = environment.origin + 'doctor/assignment/generate-prescription';
    public PrescriptionDownload_API_URL = environment.origin + 'customer/prescription/download/'
    public Doctor_DashboardGetAll_API_URL = environment.origin + 'doctor/Assignment/get-by-DoctorDashbaord_auth'
    public DoctorAssignmentOrder_API_URL = environment.origin + 'doctor/Assignment/get-by-DoctorAssignmentOrder'
    public Doctor_DashboardGetAllFilter_API_URL = environment.origin + 'doctor/Assignment/Get-filter-doctorDashboard'



    // Coupon Controller

    // public CouponGet_by_product_API_URL=environment.origin + 'discount/coupon'
    // public CouponGet_by_category_API_URL=environment.origin + 'discount/coupon'
    // public CouponGet_by_store_API_URL=environment.origin + 'discount/coupon'
    // public CouponStore_Coupon_API_URL=environment.origin + 'discount/coupon'

    public CouponGetAll_API_URL = environment.origin + 'discount/coupon'
    public CouponGetById_API_URL = environment.origin + 'discount/coupon/'
    public CouponAdd_API_URL = environment.origin + 'discount/coupon'
    public CouponUpdate_API_URL = environment.origin + 'discount/coupon'
    public CouponDelete_API_URL = environment.origin + 'discount/coupon/'
    public Coupon_Activate_Deactivate_API_URL = environment.origin + 'discount/coupon/activate-de-activate'
    public CouponDiscountImage_API_URL = environment.origin + 'discount/coupon/upload-discountimage'
    public DateFilterCoupon_API_URL = environment.origin + 'discount/coupon/DateFilterCoupon'
    public CouponSearchText_API_URL = environment.origin + 'discount/coupon/search'






    // Payment Controller

    public PaymentRefund_API_URL = environment.origin + 'sale/payment/Payment-Refund'
    public PaymentRefundGetAll_API_URL = environment.origin + 'sale/payment/Payment_Refund_All_Get'
    public PaymentRefundCreate_API_URL = environment.origin + 'sale/payment/CreateRazorpay-Refund'
    public PaymentOrderDetailsWithStore_API_URL = environment.origin + 'sale/payment/PaymentOrderDetails'
    public PaymentDashboard_API_URL = environment.origin + 'sale/payment/PaymentDashbaord'
    public PaymentRefundDateFilter_API_URL = environment.origin + 'sale/payment/DateFilterPaymentRefund'
    public PaymentDashboardDateFilter_API_URL = environment.origin + 'sale/payment/DateFilterPaymentDashbaord'
    public PaymentDashbaordfilterDateFilter_API_URL = environment.origin + 'sale/payment/PaymentDashbaordfilter'
    public RefundSearchText_API_URL = environment.origin + 'sale/payment/search'
    public PaymentOrderDetailsSerachText_API_URL = environment.origin + 'sale/payment/paymentorderdetailsserachtext'



}