import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/auth.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const currentUser = this.authenticationService.currentUserValue;
        const isLoggedIn = currentUser && currentUser.accessToken;
        const isApiUrl = request.url.startsWith(environment.origin);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.accessToken}`
                }
            });
        }
        return next.handle(request);
    }


    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     if (environment.defaultauth === 'firebase') {
    //         const currentUser = this.authenticationService.currentUser();
    //         if (currentUser && currentUser.token) {
    //             request = request.clone({
    //                 setHeaders: {
    //                     Authorization: `Bearer ${currentUser.token}`
    //                 }
    //             });
    //         }
    //     } else {
    //         // add authorization header with jwt token if available
    //         const currentUser = this.authfackservice.currentUserValue;
    //         if (currentUser && currentUser.token) {
    //             request = request.clone({
    //                 setHeaders: {
    //                     Authorization: `Bearer ${currentUser.token}`
    //                 }
    //             });
    //         }
    //     }
    //     return next.handle(request);
    // }
}
