import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './account/auth/page404/page404.component';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';

//const roleName = localStorage.getItem('roleName')



const routes: Routes = [

  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },

  // tslint:disable-next-line: max-line-length

  { path: '', component: LayoutComponent, loadChildren: () => import('./Components/component.module').then(m => m.ComponentModule), canActivate: [AuthGuard] },
  // The path of  Page not found  component
  { path: '**', component: Page404Component },


  //{ path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  // { path: '', component: LayoutComponent, loadChildren: () => import('./Components/Masters/masters.module').then(m => m.MastersModule), canActivate: [AuthGuard]},
  //  { path: '', component: LayoutComponent, loadChildren: () => import('./Components/Store/store.module').then(m => m.StoreModule)},
  // { path: '', component: LayoutComponent, loadChildren: () => import('./Components/Finance/finance.module').then(m => m.FinanceModule), canActivate: [AuthGuard]},
  // { path: '', component: LayoutComponent, loadChildren: () => import('./Components/System/system.module').then(m => m.SystemModule), canActivate: [AuthGuard]},
  // { path: '', component: LayoutComponent, loadChildren: () => import('./Components/Doctor/doctor.module').then(m => m.DoctorModule), canActivate: [AuthGuard]},
  // { path: '', component: LayoutComponent, loadChildren: () => import('./Components/Discount/discount.module').then(m => m.DiscountModule), canActivate: [AuthGuard]},



];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
