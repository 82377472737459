<div class="card mini-stats-wid">
  <div class="card-body">
    <div class="d-flex">
      <div class="flex-grow-1">
        <p class="text-muted fw-medium mb-2">{{title}}</p>
        <h4 class="mb-0">{{value}}</h4>
      </div>
      <div class="flex-shrink-0 align-self-center">
        <div class="mini-stat-icon avatar-sm rounded-circle bg-primary">
          <span class="avatar-title">
            <i class="bx {{icon}} font-size-24"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- end card-body -->
</div>
<!-- end card -->
