import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/auth.models';
import { APIURL } from './APIURL';
import { AppService } from './app.service';



@Injectable({ providedIn: 'root' })

export class AuthenticationService {

  user: User;
  roleAs: string;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  isAuthenticate: boolean = false;
  optionsWithJson = {
    headers: new HttpHeaders().set('Content-Type', 'application/json').append('Accept', 'application/json')
  };

  public urls = new APIURL();

  constructor(private appService: AppService) {

    let UserDetails = localStorage.getItem('currentUser');
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(UserDetails || '{}'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  login(email: string, password: string) {
    const loginData: any = {};
    loginData['email'] = email;
    loginData['password'] = password;
    return this.appService.post(this.urls.login_API_URL, loginData).pipe(map((data: any) => {
      if (data) {
        this.isAuthenticate = true;
        localStorage.setItem('isLoggedIn', 'true')
        localStorage.setItem('currentUser', JSON.stringify(data) || '{}');
        localStorage.setItem('roleName', data.roleName)
        this.currentUserSubject.next(data);
        return of(true, this.roleAs);
      }
      else {
        this.isAuthenticate = false;
        return of(false);

      }
    }));


  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }


  logout(): void {
    localStorage.setItem('isLoggedIn', 'false');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('roleName')
  }




  // /**
  //  * Returns the current user
  //  */
  // public currentUser(): User {
  //     return getFirebaseBackend().getAuthenticatedUser();
  // }

  // /**
  //  * Performs the auth
  //  * @param email email of user
  //  * @param password password of user
  //  */
  // login(email: string, password: string) {
  //     return getFirebaseBackend().loginUser(email, password).then((response: any) => {
  //         const user = response;
  //         return user;
  //     });
  // }

  // /**
  //  * Performs the register
  //  * @param email email
  //  * @param password password
  //  */
  // register(email: string, password: string) {
  //     return getFirebaseBackend().registerUser(email, password).then((response: any) => {
  //         const user = response;
  //         return user;
  //     });
  // }

  // /**
  //  * Reset password
  //  * @param email email
  //  */
  // resetPassword(email: string) {
  //     return getFirebaseBackend().forgetPassword(email).then((response: any) => {
  //         const message = response.data;
  //         return message;
  //     });
  // }

  // /**
  //  * Logout the user
  //  */
  // logout() {
  //     // logout the user
  //     getFirebaseBackend().logout();
  // }
}

