import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APIURL } from 'src/app/core/services/APIURL';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})
export class Page404Component implements OnInit {

  public urls = new APIURL();

  backDashboard: string;


  constructor(private appServices: AppService, private router: Router) { }

  ngOnInit(): void {
    const roleName = localStorage.getItem('roleName')
    if (roleName == 'Merchant') {
      this.backDashboard = '/stores/storedashboard';
    }
    else if (roleName == 'Doctor') {
      this.backDashboard = '/doctors/doctorsdashboard';
    }
    else if (roleName == 'SuperAdmin') {
      this.backDashboard = '/dashboard';
    }
    else if (roleName == 'Organisation') {
      this.backDashboard = '/doctors/orgdashboard';
    }
  }



}
