import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
} 

const httpOptionsDownload = {
  headers: new HttpHeaders().set('content-type', 'application/octet-stream')
} 


    let params = new HttpParams();
    params = params.append('PageNumber', 10);
    params = params.append('PageSize', 100);

    let paramsS = new HttpParams().set('paramName', 10);


export interface Parameters {
  url: string;
  parameterObject?: any
}


@Injectable({
  providedIn: 'root'
})
export class AppService {

  httpDefaults: Parameters = {
    url: '',
    parameterObject: {}
  };


  constructor(private http: HttpClient) { }

  public getObjPagination(url: string,params): Observable<any> {
    return this.http.get(url,{params: params})
  }

  
  public getObjSearching(url: string,paramsS): Observable<any> {
    return this.http.get(url,{params: paramsS})
  }
  
  public get(url: string): Observable<any> {
    return this.http.get(url, httpOptions)
  }

  public post(url: string, parameterObject?: any): Observable<any> {
    return this.http.post(url, parameterObject, httpOptions);
  }
  
  public put(url: string, parameterObject?: any): Observable<any> {
    return this.http.put(url, parameterObject, httpOptions)
  }

  public patch(url: string, parameterObject?: any): Observable<any> {
    return this.http.patch(url, parameterObject, httpOptions)
  }

  public delete(url: string): Observable<any> {
    return this.http.delete(url, httpOptions)
  }

  public download(url: string):Observable<Blob> {
    return this.http.get(url, {reportProgress: true,responseType: 'blob'});
}


public uploadfile(url: string,file: File) {
  let formParams = new FormData();
  formParams.append('profileImage', file)
  return this.http.post(url, formParams)
}


public uploadfileCsvProduct(url: string,file: File) {
  let formParams = new FormData();
  formParams.append('productFile', file)
  return this.http.post(url, formParams)
}


public uploadfileDiscountImage(url: string,file: File) {
  let formParams = new FormData();
  formParams.append('discountImage', file)
  return this.http.post(url, formParams)
}



// uploadImage(url: string,imageFile: File): Observable<Response> {
//   const formData = new FormData();
//   formData.append('discountImage', imageFile);
//   return this.http.post<Response>(url, formData);
// }


  // public postWitId(url: string): Observable<any> {
  //   return this.http.post(url, httpOptions);
  // }

  // // public PostUpload(url: string, parameterObject?: any): Observable<any> {
  // //   return this.http.post(url, parameterObject, { reportProgress: true });
  // // }
  
}




